<template>
  <div class="title">
    夜猫加速能让您享受到哪些服务
  </div>
  <div style="height: 2rem;"></div>
  <div class="desc">
    夜猫专为晚高峰做出优化，
    <br>
    不卡顿不掉线，速度和白天一样快！<br>
    忙碌一天后，让您的快乐流畅丝滑！
  </div>
</template>

<script>

  export default {
    name: 'WhyNeedYemao',
    components: {},
  }
</script>

<style scoped>
div{
  padding: 0 5%;
  font-size: 30px;
}
  .title{
    text-align: center;
    font-weight: 900;
  }
  .desc{
    color: #696969;
    text-align: center;
    font-size: 20px;
  }
@media all  and (min-width: 800px) {
  .desc{
    font-size: 30px;
  }
}

</style>
