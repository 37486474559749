<template>
  <Banner></Banner>
  <div style="height: 2rem;"></div>
  <div class="main-desc">
    <WhyNeed></WhyNeed>
    <Offer></Offer>
<!--    <Choose></Choose>-->
  </div>
    <div class="center bottom-download">
      <Download></Download>
      <div style="height: 1.1rem;"></div>
      <div>
        <a :href="`mailto:${email}`">
          客服邮箱: {{email}}
        </a>
      </div>
      <div style="height: 1.1rem;"></div>
    </div>
  <div class="copyright">
    <Copyright></Copyright>
  </div>
</template>
<script>
  import Banner from "./components/BannerYemao"
  import WhyNeed from "./components/WhyNeedYemao"
  import Offer from "./components/OfferYemao"
  import Download from "./components/DownloadYemao"
  import Copyright from "./components/CopyrightYemao"

  export default {
    name: 'AppYemao',
    components: {
      Banner,
      WhyNeed,
      Offer,
      // Choose,
      Download,
      Copyright,
    },
  }
</script>
<style>
  * {
    padding: 0;
    margin: 0;

  }

  html {
    font-size: 12px;
    background-color: #fafafa;
    font-family: Roboto,sans-serif;
  }

  .center {
    text-align: center;
  }

  .bottom-download {
    height: 8rem;
    background-color: #f5bc41;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
  }
  .bottom-download a {
    color: white;
    text-decoration: none;
  }

  .copyright {
    background-color: #f5bc41;
    color: white;
  }

  .contact-us{
    position: fixed;
    bottom: 1rem;
    right: 1rem;
  }

  @media all  and (min-width: 800px) {
    html {
      font-size: 14px;
    }
    .main-desc {
      /*max-width: 800px;*/
      padding: 2rem 10% ;
    }
  }
</style>
