import { createApp } from "vue";
import App from "./AppYemao";
import router from "./router";

let app = createApp(App)

let defaultName = "夜猫加速器"
let appName = !(window.env && window.env.appName) ? defaultName : window.env.appName === '#{APPNAME}' ? defaultName :  window.env.appName
app.config.globalProperties.appName = appName

let defaultCompanyName = "夜猫加速"
let companyName = !(window.env && window.env.companyName) ? defaultCompanyName : window.env.companyName === '#{COMPANYNAME}' ? defaultCompanyName :  window.env.companyName
app.config.globalProperties.companyName = companyName


let defultEmail = 'viphelp724@gmail.com'
let email = !(window.env && window.env.supportEmail) ? defultEmail : window.env.supportEmail === '#{SUPPORTEMAIL}' ? defultEmail :  window.env.supportEmail
app.config.globalProperties.email = email;

let defaultSlogan = '无惧晚高峰，安全稳定秒开4K <br/>   最懂你夜晚快乐的加速器'
let slogan = !(window.env && window.env.slogan) ? defaultSlogan : window.env.slogan === '#{SLOGAN}' ? defaultSlogan :  window.env.slogan
app.config.globalProperties.slogan = slogan

window.document.title = appName

import utils from "./utils/utils"
utils.statsOpen()

app.use(router).mount("#app");

